@import 'node_modules/primeflex/primeflex.scss';

@import 'prismjs/themes/prism-coy.css';
@import './AppDemo.scss';

$Primary-Color: #509793;
$Brand-White: #ffffff;

//font title
$titleTextFont: 'Poppins', sans-serif;

.Login__Input {
    padding: 15px;
}
.Login__Button {
    background-color: $Primary-Color;
    border: none;
    border-radius: 5px;
    padding: 12px;

    &:hover {
        background-color: $Primary-Color !important;
    }
}
.Signin__Heading {
    font-size: 24px;
    font-weight: 600;
    color: #000;
}
.checkbox_login {
    border: 2px solid #ced4da;
    background: $Brand-White;
    width: 22px;
    height: 22px;
    color: #495057;
    border-radius: 6px;
}
.Remember_label {
    color: #495057;
}
.forgot_label {
    color: #495057;
    margin-top: 5px;
    margin-right: 35px;
}
.Login__Input {
    width: 100%;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    background: none;
    color: #000;
}
.Login__Input:focus {
    outline: none;
    border: none;
}

.p-error {
    color: #fc6161;
    float: left;
}

.p-input-icon-left > i,
.p-input-icon-right > i,
.p-input-icon-left > svg,
.p-input-icon-right > svg,
.p-input-icon-left > .p-input-prefix,
.p-input-icon-right > .p-input-suffix {
    position: absolute;
    top: 50%;
    margin-top: -1rem;
}

.Remember__Forget {
    width: 355px;
}

// Data table Header with Search

.Global__Header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    h5 {
        color: $Primary-Color !important;
        font-weight: 600 !important;
        font-family: $titleTextFont;
    }

    .p-input-icon-left > i:first-of-type,
    .p-input-icon-left > svg:first-of-type,
    .p-input-icon-left > .p-input-prefix {
        left: 0.7rem;
        color: $Primary-Color;
        top: 1.7rem;
        font-size: 12px;
    }

    .p-inputtext {
        text-indent: 0px;
    }

    .p-inputtext::-webkit-input-placeholder {
        padding-left: 0rem;
    }
}

.Label__Text {
    margin-right: 10px;
    width: 30%;
    white-space: nowrap;
}

.Question__Validation__MSG {
    white-space: nowrap;
    padding-left: 10px;
    padding-top: 10px;
}

.p-fluid .p-dropdown {
    display: flex;
    width: 100%;
}

.InputSwitch__Active {
    margin-right: 5px;
}

.login-body .login-panel .form-container a,
.login-body .login-panel .form-container button {
    color: #868c9b;
    margin-bottom: 20px;
    font-size: 11px;
    border-bottom: 1px solid;
}

.login-body .signup-panel {
    background-repeat: no-repeat;
    text-align: center;
    width: 100%;
    padding: 90px 300px 180px 80px;
    box-sizing: border-box;
}

.p-dialog .p-dialog-content:last-of-type {
    border-bottom-right-radius: 20px;
    border-bottom-left-radius: 20px;
}

.login-body .login-image img {
    height: 100vh;
    width: 100%;
}

.login-body {
    display: flex;
    box-sizing: border-box;
    max-height: 95vh;
}

.Input__Round {
    margin-top: 5px;
}

.SignIn__ImageLogo {
    img {
        height: 300px;
        width: 300px;
    }
}

.login-body {
    .login-panel {
        background-repeat: no-repeat;
        text-align: center;
        width: 100%;
        padding: 80px 100px 75px 100px !important;
        box-sizing: border-box;
    }
}
